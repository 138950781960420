import React from "react";
import { formatHeader, formatValue } from "../../utils/tableUtils";
import { TableData, MenuOption } from "../../interfaces/globalInterfaces";
import Dropdown from "../Dropdown/Dropdown";

/** Props for the JsonTable component. */
interface JsonTableProps {
    jsonData: TableData[];
    options: MenuOption[][];
    headers: string[];
}

/**
 * Displays a table of json data, allowing for filtering depending on the headers provided. The table assumes
 * an ID is the first element of the data, where the first option is a navigation to the page showing said element.
 *
 * @param {TableData[]} jsonData - The json data to display.
 * @param {MenuOption[][]} options - The options to take on an item in a row of the table.
 * @param {string[]} headers - The headers to display from the json data.
 */
const JsonTable: React.FC<JsonTableProps> = ({
    jsonData,
    options,
    headers,
}) => {
    
    return (
        <div className="json-table-list">
            <table>
                <thead>
                    <tr>
                        <th key={0}>{formatHeader(headers[0])}</th>
                        {headers.slice(1).map((header, index) => (
                            <th key={index} className="hidden-on-resize">
                                {formatHeader(header)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {/* Display a message if the json data is empty. If not, map the items we found to
                        the table. */}
                    {jsonData.length === 0 ? "No data to display." : jsonData.map((item, index) => (
                        <tr key={index}>
                            {/* We slice the json to allow for a certain action on the first item */}
                            <td
                                key="0"
                                className="json-clickable-id"
                                onClick={() =>
                                    (options[index][0].action as (id: string) => void)(
                                        item[headers[0]]
                                    )
                                }
                            >
                                {item[headers[0]]}
                            </td>
                            {headers.slice(1).map((header, colIndex) => (
                                <td key={colIndex} className="hidden-on-resize">
                                    {formatValue(item[header].toString(), header)}
                                </td>
                            ))}
                            <td className="json-table-dropdown">
                                {/* This requires the object id to be the first element */}
                                <Dropdown
                                    menuOptions={options[index]}
                                    useIcon={true}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default JsonTable;
