import React, { useState } from "react";
import DeleteButton from "../../Buttons/DeleteButton/DeleteButton";
import ClusterDeleteModal from "../ClusterDeleteModal/ClusterDeleteModal";

/** Props for the CapturePost component. */
interface ClusterDeleteProps {
    clusterName: string;
    clusterId: string;
}

/**
 * Adds cluster.
 *
 */
const ClusterDelete: React.FC<ClusterDeleteProps> = ( { clusterName, clusterId } ) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <div>
            <DeleteButton onClick={toggleModal} />
            <ClusterDeleteModal clusterId={clusterId} clusterName={clusterName} open={showModal} onClose={toggleModal} /> 
        </div>
    );
};

export default ClusterDelete;