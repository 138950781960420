import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    deleteFromProtectedResource,
    getAPIEndpoint,
} from "../../../utils/apiUtils";
import { useSiteContext } from "../../../utils/siteUtils";
import Modal from "../../Modal/Modal";

/** Props for the CapturePost component. */
interface ClusterDeleteProps {
    clusterName: string;
    clusterId: string;
    open: boolean;
    onClose: () => void;
}

/**
 * Adds cluster.
 *
 */
const ClusterDeleteModal: React.FC<ClusterDeleteProps> = ( { clusterName, clusterId, open, onClose } ) => {
    const { getAccessTokenSilently } = useAuth0();
    const [showResponse, setShowResponse] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("Delete Cluster");
    const { site } = useSiteContext();

    const clusterEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_CLUSTER_URL}/${clusterId}`
    );
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const accessToken = await getAccessTokenSilently();
        event.preventDefault();
        
        try {
            const response = await deleteFromProtectedResource(clusterEndpoint, accessToken);
            if (response.status === 400) {
                setShowResponse(true);
                setTitle("Error deleting cluster: " + response.message);
            } else {
                setShowResponse(true);
                setTitle("Cluster " + clusterId + " deleted successfully");
            }

        } catch (error) {
            console.error("Error deleting data: ", error);
        }
    };

    const toggleModal = () => {
        onClose();
        setShowResponse(false);
        setTitle("Delete Cluster");
    };

    return (
        <div>
            <Modal
                title={title}
                open={open}
                onClose={toggleModal}
            >
                <div>
                    {/* Only show the form data if we don't have any response set. */}
                    {!showResponse && 
                    <form className="modal-form" onSubmit={handleSubmit}>
                        <label className="modal-label">
                            Cluster Name:
                            <input
                                value={clusterName}
                                type="text"
                                name="name"
                                className="modal-input"
                                disabled={clusterName.length > 0}
                            />
                        </label>
                        <label className="modal-label">
                            Cluster Id:
                            <input
                                value={clusterId}
                                type="text"
                                name="name"
                                className="modal-input"
                                disabled={clusterId.length > 0}
                            />
                        </label>
                        <input
                            type="submit"
                            value="Submit"
                            className="text-button"
                        />
                    </form>}
                </div>
            </Modal>
        </div>
    );
};

export default ClusterDeleteModal;