import React from "react";
// import { cache } from "react";
import { useSiteContext } from "../../../utils/siteUtils";
import { setCurrentSite } from "../../../utils/storageUtils";
import Dropdown from "../../Dropdown/Dropdown";
import { MenuOption } from "../../../interfaces/globalInterfaces";
import { getAPIEndpoint, getProtectedResource } from "../../../utils/apiUtils";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * The header for selecting the site to be used.
 */
const HeaderSite: React.FC = () => {
    const storedSites = process.env.REACT_APP_SITES;
    const { site, setSite } = useSiteContext();
    const { getAccessTokenSilently } = useAuth0();
    const clusterEndpoint = getAPIEndpoint(
        `${process.env.REACT_APP_TE}`,
        `${process.env.REACT_APP_TE_CLUSTER_URL}`
    );

    if (storedSites === undefined) {
        return null;
    }

    const getClusterData = async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const clusterData: { [index: string]: any } =
                await getProtectedResource(
                    clusterEndpoint!,
                    accessToken
                );
            console.log("Cluster data: " + clusterData);
            // return clusterData.clusterName.toString();
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
      };
      

    // const getCachedSites = cache(getClusterData);
    // const sites = getCachedSites();
    const sites = process.env.REACT_APP_SITES!.split(",");

    if (!sites) {
        return null;
    }

    const siteHandler = (dropSite: string) => {
        setSite(dropSite);
        setCurrentSite(dropSite);
    };

    /* If there's no site context, set it to the first option */

    if (!site) {
        siteHandler(sites[0]);
    }

    /* Set the options and their action for the dropdown menu. */

    let menuOptions: MenuOption[] = [];
    sites.forEach((site) => {
        menuOptions.push({ label: site, action: siteHandler, input: site });
    });

    return (
        <div className="header-site">
            <Dropdown
                menuOptions={menuOptions}
                buttonPrefix="Site"
                useIcon={false}
                defaultOption={site}
            />
        </div>
    );
};

export default HeaderSite;
