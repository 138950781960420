import React from "react";
import { useParams } from "react-router-dom";
import PageLayout from "../../../components/PageLayout/PageLayout";
import ClusterDetails from "../../../components/Cluster/ClusterDetails/ClusterDetails";

/**
 * Displays the information for a single cluster, getting the UUID from the url.
 */
const SystemClustersIdPage: React.FC = () => {
    const { uuid } = useParams<{ uuid: string }>();

    return (
        <PageLayout>
            <ClusterDetails uuid={uuid!} />
        </PageLayout>
    );
};

export default SystemClustersIdPage;
