import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ObjectSummaryTable from "../../Tabs/ObjectSummaryTable/ObjectSummaryTable";
import ClusterNodesTab from "../Tabs/ClusterNodesTab/ClusterNodesTab";
import TabBar from "../../TabBar/TabBar";
import RefreshButton from "../../Buttons/RefreshButton/RefreshButton";
import { getProtectedResource, getAPIEndpoint } from "../../../utils/apiUtils";
import { useSiteContext } from "../../../utils/siteUtils";
import { TableData } from "../../../interfaces/globalInterfaces";
import ClusterDelete from "../ClusterDelete/ClusterDelete";

/** Props for the ClusterDetails component. */
interface ClusterDetailsProps {
    uuid: string;
}

/**
 * Displays the details of a cluster.
 *
 * @param {string} uuid - The UUID of the cluster to display.
 */
const ClusterDetails: React.FC<ClusterDetailsProps> = ({ uuid }) => {
    const [jsonData, setJsonData] = useState<TableData>();
    const [clusterName, setClusterName] = useState<string>("");
    const { site } = useSiteContext();
    const clusterEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_CLUSTER_URL}/${uuid!}`
    );
    const { getAccessTokenSilently } = useAuth0();

    /** Get and set the cluster data from the TE. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const clusterData: TableData = await getProtectedResource(
                clusterEndpoint!,
                accessToken
            );
            setJsonData(clusterData);
            setClusterName(clusterData.clusterName);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, clusterEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /** Refresh the data on clicking the refresh button. */
    const refreshData = () => {
        fetchData();
    };

    /** Cluster tabs */
    const tabs = [
        "Child Nodes"
    ];

    /* Message to display when cluster not found. */

    if (jsonData?.error && jsonData?.message) {
        return <>Cluster with uuid: {uuid} not found.</>;
    }

    return (
        <div className="object-summary">
            <h1>Cluster Details - {uuid}</h1>
            <div className="summary-container">
                <h2 className="summary-title">Summary</h2>
                <ClusterDelete clusterId={uuid} clusterName={clusterName} />
                <RefreshButton onClick={refreshData} />
            </div>
            {jsonData && <ObjectSummaryTable selectedObject={jsonData} />}
            <TabBar tabs={tabs}>
                <ClusterNodesTab uuid={uuid} />
            </TabBar>
        </div>
    );
};

export default ClusterDetails;
