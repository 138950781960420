import React from "react";

/** Props for the ClusterButton component. */
interface ClusterButtonProps {
    onClick: () => void;
}

/**
 * A button for submitting.
 * 
 * @param onClick - The action to be taken when the button is clicked.
 */
const ClusterButton: React.FC<ClusterButtonProps> = ({ onClick }) => {
    return (
        <button className="text-button" onClick={onClick} aria-label="Add Cluster">
            Add Cluster
        </button>
    );
}

export default ClusterButton;
