import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    getAPIEndpoint,
    postToProtectedResource,
} from "../../../utils/apiUtils";
import { useSiteContext } from "../../../utils/siteUtils";
import { ClusterPayload } from "../../../interfaces/clusterInterfaces";
import Modal from "../../Modal/Modal";
import ClusterButton from "../../Buttons/ClusterButton/ClusterButton";

/** Props for the CapturePost component. */
interface ClusterPostProps {
    onSubmit: () => void;
}

/**
 * Adds cluster.
 *
 */
const ClusterPost: React.FC<ClusterPostProps> = ( { onSubmit } ) => {
    const { getAccessTokenSilently } = useAuth0();
    const [clusterName, setClusterName] = useState<string>("");
    const [active, setActive] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { site } = useSiteContext();
    const clusterEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_CLUSTER_URL}`
    );

    const handleClusterNameChange = (
        e: React.FormEvent<HTMLInputElement>
    ): void => {
        setClusterName(e.currentTarget.value);
    };

    const handleActiveChange = (e: React.FormEvent<HTMLInputElement>): void => {
        setActive(e.currentTarget.checked);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        toggleModal();
        const accessToken = await getAccessTokenSilently();
        event.preventDefault();

        if (!/^[a-zA-Z_-]+$/.test(clusterName)) {
            console.log("Incorrect name format, please only use letters or '-'");
            return false;
        }
        
        try {
            const payload: ClusterPayload = {
                clusterName: clusterName,
                active: active
            };
            
            postToProtectedResource(clusterEndpoint, accessToken, payload);
        } catch (error) {
            console.error("Error posting data: ", error);
        }
        onSubmit();
    };

    return (
        <div>
            <ClusterButton onClick={toggleModal} />
            <Modal
                title="Add Cluster"
                open={showModal}
                onClose={toggleModal}
            >
                <form className="modal-form" onSubmit={handleSubmit}>
                    <label className="modal-label">
                        Cluster Name:
                        <input
                            value={clusterName}
                            type="text"
                            name="name"
                            className="modal-input"
                            onChange={handleClusterNameChange}
                        />
                    </label>
                    <label className="modal-label">
                        Active:
                        <input
                            type="checkbox"
                            name="name"
                            className="modal-input-checkbox"
                            onChange={handleActiveChange}
                        />
                    </label>
                    <input
                        type="submit"
                        value="Submit"
                        className="text-button"
                    />
                </form>
            </Modal>
        </div>
    );
};

export default ClusterPost;