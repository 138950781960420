import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PageLayout from "../../../components/PageLayout/PageLayout";
import JsonTable from "../../../components/JsonTable/JsonTable";
import { getAPIEndpoint, getProtectedResource } from "../../../utils/apiUtils";
import { TableData, MenuOption } from "../../../interfaces/globalInterfaces";
import { useSiteContext } from "../../../utils/siteUtils";
import ClusterPost from "../../../components/Cluster/ClusterPost/ClusterPost";
import { Cluster } from "../../../interfaces/clusterInterfaces";
import ClusterDeleteModal from "../../../components/Cluster/ClusterDeleteModal/ClusterDeleteModal";

/**
 * Displays an overview of the information for all clusters in the system.
 */
const SystemClustersPage: React.FC = () => {
    const [jsonData, setJsonData] = useState<TableData[]>([]);
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const [clusterId, setClusterId] = useState<string>("");
    const [clusterName, setClusterName] = useState<string>("");
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const { site } = useSiteContext();
    const clusterEndpoint = getAPIEndpoint(
        site,
        process.env.REACT_APP_TE_CLUSTER_URL!
    );

    /** Navigate to the selected node's page. */
    const redirectToClustersPage = (defaultPath: string) => (uuid: string) => {
        const path = `${defaultPath}/${uuid}`;
        navigate(path);
    };

    const setShowDeleteModalAction = (show: boolean) => (cluster: Cluster) => {
        setClusterId(cluster.clusterId);
        setClusterName(cluster.clusterName);
        setShowDeleteModal(show);
    }

    /* Set the options and their action for the dropdown menu. */

    let menuOptions: MenuOption[][] = [];
    jsonData.forEach((object) => {
        menuOptions.push([{
            label: "View",
            action: redirectToClustersPage("/system/clusters"),
            input: object.clusterId,
        },
        {
            label: "Delete",
            action: setShowDeleteModalAction(true),
            input: object,
        }]);
    });

    const toggleModal = () => {
        setShowDeleteModal(!showDeleteModal);
        fetchData();
    };

    /** The headers of the node information to display. */
    const headers = ["clusterId", "clusterName", "active"];

    /** Get and set the cluster data from the TE. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const clusterData = await getProtectedResource(
                clusterEndpoint!,
                accessToken
            );
            setJsonData(clusterData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, clusterEndpoint]);


    /* Get the clusters's information, and show the error if set. */
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <PageLayout>
            <>
                <ClusterDeleteModal clusterId={clusterId} clusterName={clusterName} open={showDeleteModal} onClose={toggleModal} />
                <div className="page-layout-header">
                    <h1>Clusters</h1>
                    <ClusterPost onSubmit={fetchData} />
                </div>
                <JsonTable
                    jsonData={jsonData}
                    options={menuOptions}
                    headers={headers}
                />
            </>
        </PageLayout>
    );
};

export default SystemClustersPage;
