import { Auth0Provider, AppState } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { JSX } from "react";

/** Props for the Auth0Wrapper component. */
interface Auth0WrapperProps {
  children: React.ReactNode;
}

/**
 * Wrapper for content to ensure it is protected by our Auth0 configuration.
 * 
 * @param {React.ReactNode} children - The content to be protected.
 */
const Auth0Wrapper = ({ children }: PropsWithChildren<Auth0WrapperProps>): JSX.Element | null => {
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    const onRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri && audience)) {
        return null;
    }

    return (
        <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
            audience: audience,
            redirect_uri: redirectUri,
        }}
        onRedirectCallback={onRedirectCallback}
        >
        {children}
        </Auth0Provider>
    );
};

export default Auth0Wrapper;
