import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PageLayout from "../../../components/PageLayout/PageLayout";
import JsonTable from "../../../components/JsonTable/JsonTable";
import { getAPIEndpoint, getProtectedResource } from "../../../utils/apiUtils";
import { TableData, MenuOption } from "../../../interfaces/globalInterfaces";
import { useSiteContext  } from "../../../utils/siteUtils";

/**
 * Displays an overview of the information for all profiles in the system.
 */
const DdosProfilesPage: React.FC = () => {
    const [jsonData, setJsonData] = useState<TableData[]>([]);
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const profileEndpoint = getAPIEndpoint(
        site,
        process.env.REACT_APP_TE_PROFILE_URL!
    );

    /** Navigate to the selected profile's page. */
    const redirectToProfilePage = (defaultPath: string) => (uuid: string) => {
        const path = `${defaultPath}/${uuid}`;
        navigate(path);
    };

    /* Set the options and their action for the dropdown menu. */

    let menuOptions: MenuOption[][] = [];
    jsonData.forEach((object) => {
        menuOptions.push([
            {
            label: "View",
            action: redirectToProfilePage("/ddos/profiles"),
            input: object.profileId,
            }
        ]);
    });

    /** The headers of the profile information to display. */
    const headers = ["profileId", "profileName"];

    /* Get the profile's information, and show the error if set. */

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            try {
                const profilesData = await getProtectedResource(
                    profileEndpoint!,
                    accessToken
                );
                setJsonData(profilesData);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, [getAccessTokenSilently, profileEndpoint]);

    return (
        <PageLayout>
            <>
                <h1>Profiles</h1>
                <JsonTable
                    jsonData={jsonData}
                    options={menuOptions}
                    headers={headers}
                />
            </>
        </PageLayout>
    );
};

export default DdosProfilesPage;
