/* Interfaces */

/** Represents json data. */
interface TableData {
    [key: string]: any;
} 

/* REST functions */

/**
 * Generates the GET config required when making a GET request to our external TE.
 * 
 * @param {string} accessToken - The authorization Bearer token from Auth0.
 * 
 * @returns Configuration to be used with a fetch() call.
 */
const getConfig = (accessToken : string ) =>
{ 
    return {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    }
}

/**
 * Generates the POST config required when making a POST request to our external TE.
 * 
 * @param {string} accessToken - The authorization Bearer token from Auth0.
 * @param {any} data - The data to be posted.
 * 
 * @returns Configuration to be used with a fetch() call.
 */
const postConfig = ( accessToken : string, data : any ) =>
{
    return {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    }
}

/**
 * Generates the DELETE config required when making a DELETE request to our external TE.
 * 
 * @param {string} accessToken - The authorization Bearer token from Auth0.
 * @param {any} data - The data to be posted.
 * 
 * @returns Configuration to be used with a fetch() call.
 */
const deleteConfig = ( accessToken : string ) =>
    {
        return {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
    }

/**
 * Generates the PUT config required when making a PUT request to our external TE.
 * 
 * @param {string} accessToken - The authorization Bearer token from Auth0.
 * @param {any} data - The data to be posted.
 * 
 * @returns Configuration to be used with a fetch() call.
 */
const putConfig = ( accessToken : string, data : any ) =>
    {
        return {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(data)
        }
    }

/**
 * Construct a GET endpoint, including the selected site.
 * 
 * @param {string} site - The site to interact with.
 * @param {string} endpoint - The endpoint to make a GET request to.
 * 
 * @returns The endpoint string.
 */
export const getAPIEndpoint = (site: string, endpoint: string): string => {
    let restEndpoint = "https://" + site + endpoint;
    return restEndpoint!;
}

/**
 * Make a GET request to a protected TE endpoint and return the data.
 * 
 * @param {string} url - The URL to call. 
 * @param {string} accessToken - The authorization Bearer token from Auth0.
 * 
 * @returns The data received from a GET request in json form.
 */
export const getProtectedResource = async (url : string, accessToken : string): Promise<TableData[]> => {
    try {
        const response = await fetch(url, getConfig(accessToken));
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`API request failed with status: ${error}`);
    }
}

/**
 * Make a GET request to a protected TE endpoint and download the data.
 * 
 * @param {string} url - The URL to call.
 * @param {string} accessToken - The authorization Bearer token from Auth0.
 * @param {string} filename - The filename to show in the browser.
 */
export const downloadProtectedResource = async (url : string, accessToken : string, filename : string) => {
    try {
        fetch(url, getConfig(accessToken))
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = filename || "downloaded-file";
                document.body.appendChild(link);
        
                link.click();
        
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
              });
    } catch (error) {
        throw new Error(`API request failed with status: ${error}`);
    }

}

/**
 * Make a POST request to a protected TE endpoint and return the data.
 * 
 * @param {string} url - The URL to call. 
 * @param {string} accessToken - The authorization Bearer token from Auth0.
 * @param {any} dataToPost - The data to post to the endpoint.
 * 
 * @returns The response received from a POST request in json form.
 */
export const postToProtectedResource = async (url : string, accessToken : string, dataToPost : any) => {
    try {
        const response = await fetch(url, postConfig(accessToken, dataToPost));
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        throw new Error(`API request failed with status: ${error}`);
    }
}

/**
 * Make a DELETE request to a protected TE endpoint and return the data.
 * 
 * @param {string} url - The URL to call.
 * @param {string} accessToken - The authorization Bearer token from Auth0.
 * 
 * @returns The response received from a DELETE request in json form.
 */
export const deleteFromProtectedResource = async (url : string, accessToken : string) => {
    try {
        const response = await fetch(url, deleteConfig(accessToken));
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        throw new Error(`API request failed with status: ${error}`)
    }
}

/** 
 * Make a PUT request to a protected TE endpoint and return the data.
 * 
 * @param {string} url - The URL to call. 
 * @param {string} accessToken - The authorization Bearer token from Auth0.
 * @param {any} dataToPost - The data to post to the endpoint.
 * 
 * @returns The response received from a PUT request in json form.
 */
export const putToProtectedResource = async (url : string, accessToken : string, dataToPost : any) => {
    try {
        const response = await fetch(url, putConfig(accessToken, dataToPost));
        const responseData = await response;
        return responseData;
    } catch (error) {
        throw new Error(`API request failed with status: ${error}`);
    }
}
