import React from "react";
import { formatHeader, formatValue } from "../../../utils/tableUtils";
import { TableData } from "../../../interfaces/globalInterfaces";

/** Props for the ObjectSummaryTable component. */
interface SelectedObjectDisplayProps {
    selectedObject: TableData;
}

/**
 * Displays a table summary of an object in json form.
 *
 * @param {DataObject} selectedObject - The json of the object to display.
 */
const ObjectSummaryTable: React.FC<SelectedObjectDisplayProps> = ({
    selectedObject,
}) => {
    return (
        <div>
            <div className="object-summary-table-container">
                <div className="object-summary-table">
                    {Object.keys(selectedObject).map((key) => (
                        <div key={key} className="object-summary-item">
                            <div className="object-summary-key">
                                {formatHeader(key)}
                            </div>
                            <div className="object-summary-value">
                                {formatValue(selectedObject[key].toString(), key)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ObjectSummaryTable;
