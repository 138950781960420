import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import JsonTable from "../../JsonTable/JsonTable";
import { MenuOption, TableData } from "../../../interfaces/globalInterfaces";
import { getAPIEndpoint, getProtectedResource } from "../../../utils/apiUtils";
import { useSiteContext } from "../../../utils/siteUtils";

/** Props for the ActiveRoutesTab component. */
interface ActiveRoutesTabProps {
    uuid: string;
    parent: string;
}

/**
 * Shows the actives routes associated with either a node or a policy.
 *
 * @param {string} uuid - The UUID of the object to query.
 * @param {string} parent - The object calling the function (e.g. 'node', 'policy').
 */
const ActiveRoutesTab: React.FC<ActiveRoutesTabProps> = ({ uuid, parent }) => {
    const [jsonData, setJsonData] = useState<TableData[]>([]);
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    var routeEndpoint: string = "";

    /* Set the endpoint depending on the parent object type. */

    if (parent === "node")
        routeEndpoint = getAPIEndpoint(
            site,
            `${process.env.REACT_APP_TE_NATIVE_NODE_URL}/${uuid}/routes`
        );
    else if (parent === "policy")
        routeEndpoint = getAPIEndpoint(
            site,
            `${process.env.REACT_APP_TE_NATIVE_ROUTE_URL}/${uuid}`
        );

    /* Set the options and their action for the dropdown menu. */

    let menuOptions: MenuOption[][] = [];
    jsonData.forEach((object) => {
        menuOptions.push([{
            label: "< None >",
            action: () => console.log("< None > clicked"),
            input: null,
        }]);
    });

    /** The route headers. */
    const headers = ["routeDescription", "node", "type", "prefix", "nextHop"];

    /** Get the routes for the object, and display an error if set. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const routeNodeData: { [index: string]: any } =
                await getProtectedResource(routeEndpoint!, accessToken);
            setJsonData(JSON.parse(routeNodeData["result"])["routes"]);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, routeEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <div>
                <JsonTable
                    jsonData={jsonData}
                    options={menuOptions}
                    headers={headers}
                />
            </div>
        </div>
    );
};

export default ActiveRoutesTab;
