import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import SubsectionMenu from "../HeaderSubsectionMenu/HeaderSubsectionMenu";
import ThemeButton from "../../Buttons/ThemeButton/ThemeButton";

/** The sections for the site pages. */
const sections = ['System', 'DDoS Mitigation'];

/** The site pages in their respective sections. */
const subsections = {
    'System': [
        { name: 'Clusters', path: '/system/clusters'},
        { name: 'Nodes', path: '/system/nodes' }, 
        { name: 'Zones', path: '/system/zones' }, 
        { name: 'Tenants', path: '/system/tenants' }, 
        { name: 'Users', path: '/system/users' }
    ],
    'DDoS Mitigation': [
        { name: 'Policies', path: '/ddos/policies' }, 
        { name: 'Profiles', path: '/ddos/profiles' },
        { name: 'Events', path: '/ddos/events' },
        { name: 'Captures', path: '/ddos/captures' }
    ],
};

/** 
 * The menu toggle to display the pages, aligned to the left of the header. 
 */
const HeaderMenuToggle: React.FC = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const headerMenuRef = useRef<HTMLDivElement>(null);

    /* Close the menu if the user clicks outside of it. */

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (headerMenuRef.current && !headerMenuRef.current.contains(event.target as Node)) {
                setMenuOpen(false);
            }
        }
    
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    /* A toggle for opening and closing the menu. */
    
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    return (
        <div ref={headerMenuRef} className="header-menu">
            <button className='menu-toggle' onClick={toggleMenu} aria-label="Menu Toggle">
                <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
            </button>
            <ThemeButton />
            <div className={`menu-sidebar ${menuOpen ? 'open' : ''}`}>
                <SubsectionMenu sections={sections} subsections={subsections} />
            </div>
        </div>
    );
};

export default HeaderMenuToggle;
