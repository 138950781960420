import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import JsonTable from "../../../JsonTable/JsonTable";
import { MenuOption, TableData } from "../../../../interfaces/globalInterfaces";
import {
    getAPIEndpoint,
    getProtectedResource,
} from "../../../../utils/apiUtils";
import { useSiteContext } from "../../../../utils/siteUtils";

/** Props for the ChildNodesTab component. */
interface ClusterNodesTabProps {
    uuid: string;
}

/**
 * Display the child nodes for a selected node.
 *
 * @param {string} uuid - The ID of the node to interface with.
 */
const ClusterNodesTab: React.FC<ClusterNodesTabProps> = ({ uuid }) => {
    const navigate = useNavigate();
    const [jsonData, setJsonData] = useState<TableData[]>([]);
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const clusterNodeEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_NODE_URL}/cluster/${uuid}`
    );

    /** Navigate to the selected node's page. */
    const redirectToNodePage = (defaultPath: string) => (uuid: string) => {
        const path = `${defaultPath}/${uuid.toLowerCase()}`;
        navigate(path);
    };

    /* Create the menu options. This assumes that the ID to use with the action is the first item of the object. */

    let menuOptions: MenuOption[][] = [];
    jsonData.forEach((object) => {
        menuOptions.push([
            {
                label: "View",
                action: redirectToNodePage("/system/nodes"),
                input: object.nodeId,
            },
        ]);
    });

    /** The headers of the policy information to display. */
    const headers = [
        "nodeId",
        "hostname",
        "personaType",
        "buildVersion",
        "zoneName",
        "lastSeen"
    ];

    /** Get the child node for the node, and display an error if set. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const clusterNodeData: TableData[] =
                await getProtectedResource(clusterNodeEndpoint!, accessToken);
            setJsonData(clusterNodeData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, clusterNodeEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <JsonTable
                jsonData={jsonData}
                options={menuOptions}
                headers={headers}
            />
        </div>
    );
};

export default ClusterNodesTab;
